import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/src/View';
import Map from '@emcasa/ui-dom/src/Map';
import Marker from '@emcasa/ui-dom/src/Map/Marker';
import { MultiMarkerBase } from '@emcasa/ui-dom/src/Map/MultiMarker';
import PaginatedMultiMarker from '@emcasa/ui-dom/src/Map/PaginatedMultiMarker';
import ButtonControl from '@emcasa/ui-dom/src/Map/ButtonControl';
import SelectControl from '@emcasa/ui-dom/src/Map/SelectControl';
import Icon from '@emcasa/ui-dom/src/Icon';
import PlaceSearch from './PlaceSearch';
import PawMarker from './PawMarker';
import markers from './markers.json';
import { GOOGLE_MAPS_API_KEY } from '../config';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "map"
    }}>{`Map`}</h1>
    <h2 {...{
      "id": "map-1"
    }}>{`Map`}</h2>
    <Props of={Map} mdxType="Props" />
    <h3 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h3>
    <p>{`All markers must be direct children of `}<inlineCode parentName="p">{`<Map />`}</inlineCode>{` and should have `}<inlineCode parentName="p">{`lat`}</inlineCode>{` and `}<inlineCode parentName="p">{`lng`}</inlineCode>{` props.`}</p>
    <Fragment mdxType="Fragment">
  <View m={4} height={400} mdxType="View">
    <Map apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} mdxType="Map">
      <View {...markers[0]} mt={-25} width={25} height={25} mdxType="View">
        <Icon name="map-marker-alt" color="pink" size={25} mdxType="Icon" />
      </View>
    </Map>
  </View>
    </Fragment>
    <h2 {...{
      "id": "mapmarker"
    }}>{`Map.Marker`}</h2>
    <Props of={Marker} mdxType="Props" />
    <p><inlineCode parentName="p">{`Map.Marker`}</inlineCode>{`s get clustered according to the parent `}<inlineCode parentName="p">{`Map`}</inlineCode>{`'s supercluster options.`}</p>
    <p>{`When clustering is enabled and the zoom level is greater than `}<inlineCode parentName="p">{`cluster.maxZoom`}</inlineCode>{` (defaults to `}<inlineCode parentName="p">{`17`}</inlineCode>{`), markers on the same spot are clustered into multi markers.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`multiMarkerRadius`}</inlineCode>{` to adjust the cluster radius in pixels. `}<inlineCode parentName="p">{`0`}</inlineCode>{` only groups markers in the same coordinates.`}</p>
    <h3 {...{
      "id": "basic-usage-1"
    }}>{`Basic usage`}</h3>
    <Fragment mdxType="Fragment">
  <View m={4} height={400} mdxType="View">
    <Map cluster apiKey={GOOGLE_MAPS_API_KEY} isHighlight={({
          id
        }) => id == markers[0].id} onChange={(bounds, listings) => console.log('onChange', {
          bounds,
          listings
        })} mdxType="Map">
      {markers.map(props => <Map.Marker {...props} key={props.id} onSelect={() => console.log('onSelect', props.id)}>
          {String(props.text)}
        </Map.Marker>)}
    </Map>
  </View>
    </Fragment>
    <h2 {...{
      "id": "mapmultimarker"
    }}>{`Map.MultiMarker`}</h2>
    <Props of={MultiMarkerBase} mdxType="Props" />
    <h2 {...{
      "id": "mappaginatedmultimarker"
    }}>{`Map.PaginatedMultiMarker`}</h2>
    <Props of={PaginatedMultiMarker} mdxType="Props" />
    <h3 {...{
      "id": "configuring-cluster-components"
    }}>{`Configuring cluster components`}</h3>
    <Fragment mdxType="Fragment">
  <View m={4} height={400} mdxType="View">
    <Map cluster apiKey={GOOGLE_MAPS_API_KEY} ClusterMarker={PawMarker} MultiMarker={props => <Map.PaginatedMultiMarker {...props} borderRadius={10} onChangePage={(point, index) => console.log('onChangePage', point, index)} />} mdxType="Map">
      {['https://bit.ly/2VQOSeF', 'https://bit.ly/30IXSBs', 'https://bit.ly/2JwCtWu'].map(url => <Map.Marker {...markers[0]} id={url} key={url} width={200} height={200} bg="white" p={0}>
          <img src={url} style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            }} />
        </Map.Marker>)}
    </Map>
  </View>
    </Fragment>
    <h2 {...{
      "id": "mapbuttoncontrol"
    }}>{`Map.ButtonControl`}</h2>
    <Fragment mdxType="Fragment">
  <View m={4} height={400} mdxType="View">
    <PlaceSearch type="park" mdxType="PlaceSearch">
      {({
            active,
            places,
            onMapLoaded,
            onChange,
            onTogglePlaces
          }) => <Map apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} onMapLoaded={onMapLoaded} onChange={onChange} mdxType="Map">
          <Map.ButtonControl active={active} position="top-center" onClick={onTogglePlaces}>
            Show nearby parks
          </Map.ButtonControl>
          <View {...markers[0]} mt={-25} width={25} height={25} mdxType="View">
            <Icon name="map-marker-alt" color="pink" size={25} mdxType="Icon" />
          </View>
          {active && places.map(({
              id,
              name,
              geometry
            }) => <View id={id} key={id} lat={geometry.location.lat()} lng={geometry.location.lng()} mt={-12.5} width={25} height={25} mdxType="View">
              <Icon name="tree" color="green" size={25} mdxType="Icon" />
            </View>)}
        </Map>}
    </PlaceSearch>
  </View>
    </Fragment>
    <h2 {...{
      "id": "mapselectcontrol"
    }}>{`Map.SelectControl`}</h2>
    <Fragment mdxType="Fragment">
  <View m={4} height={400} mdxType="View">
    <Map apiKey={GOOGLE_MAPS_API_KEY} mdxType="Map">
      <Map.SelectControl label="Select control" height="short" fontSize="small" position="top-right" zIndex={100} onChange={console.log}>
        <Map.SelectControl.Option color="extraDarkSmoke" value={null}>
          Select an option
        </Map.SelectControl.Option>
        {['A', 'B', 'C'].map(name => <Map.SelectControl.Checkbox key={name} value={name}>
            Option {name}
          </Map.SelectControl.Checkbox>)}
      </Map.SelectControl>
      <View {...markers[0]} mt={-25} width={25} height={25} mdxType="View">
        <Icon name="map-marker-alt" color="pink" size={25} mdxType="Icon" />
      </View>
    </Map>
  </View>
    </Fragment>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      